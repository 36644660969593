import { AxiosResponse, AxiosRequestConfig } from 'axios'

export class AxiosNdJsonInterceptor {
  // TODO_NEXT: this should return Promise<AxiosRequestConfig> instead of any, but types seem to be broken:
  // https://github.com/axios/axios/issues/5494 maybe upgrading fixes it
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public interceptRequest (config: AxiosRequestConfig): Promise<any> {
    if (!config.url?.includes('format=') && (!config.params || (config.params && !('format' in config.params)))) {
      config.params = config.params ?? {}
      config.params.format = 'ndjson'
    }
    return Promise.resolve(config)
  }

  public async interceptResponse (response: AxiosResponse): Promise<AxiosResponse> {
    const contentType = response.headers['content-type']
    // TODO - remove
    console.log('start of response')
    console.time('response')
    if (contentType && contentType.includes('text/x-ndjson') && this.isReadableStream(response.data)) {
      const parsedData = await this.ndjsonStreamHelper(response.data)
      console.log('end of response')
      console.time('response')
      response = { ...response, data: parsedData }
    } else if ('locked' in response.data && response.data.locked) {
      // TODO: handle locked?
      response = { ...response, data: response.data }
    } else if (contentType && contentType.includes('json') && this.isReadableStream(response.data)) {
      const obj = await new Response(response.data).json()
      response = { ...response, data: obj }
    } else if (this.isReadableStream(response.data)) {
      const text = await new Response(response.data).text()
      response = { ...response, data: text }
    }

    return Promise.resolve(response)
  }

  private isReadableStream (input: unknown): input is ReadableStream<Uint8Array> {
    return (
      typeof input === 'object' &&
      input !== null &&
      typeof (input as ReadableStream<Uint8Array>).getReader === 'function'
    )
  }

  public async ndjsonStreamHelper (response: ReadableStream<Uint8Array>): Promise<unknown[]> {
    const isReader = response.getReader()
    let cancellationRequest = false

    const stream = new ReadableStream<unknown>({
      async start (controller) : Promise<void> {
        const decoder = new TextDecoder('utf-8', { fatal: true })
        let buffer = ''

        // eslint-disable-next-line no-unmodified-loop-condition
        while (!cancellationRequest) {
          const { done, value } = await isReader.read()
          if (done) {
            break
          }

          // Decode the incoming Uint8Array into a string
          buffer += decoder.decode(value, { stream: true })

          // Split the buffer into lines
          const lines = buffer.split('\n')

          // The last line may be incomplete, keep it in the buffer
          buffer = lines.pop()!

          // Process each line
          for (const line of lines) {
            if (line.trim()) {
              try {
                controller.enqueue(JSON.parse(line))
              } catch (e) {
                controller.error(`Failed to parse line as JSON: ${line}`)
                return
              }
            }
          }
        }

        // Process any remaining data in the buffer
        if (buffer.trim()) {
          try {
            controller.enqueue(JSON.parse(buffer))
          } catch (e) {
            controller.error(`Failed to parse line as JSON: ${buffer}`)
          }
        }

        controller.close()
      },
      cancel (reason): void {
        cancellationRequest = true
        isReader.cancel(reason)
      }
    })

    // Read the final stream to return unknown[]
    const reader = stream.getReader()
    const result: unknown[] = []

    while (true) {
      const { done, value } = await reader.read()
      if (done) {
        break
      }
      result.push(value)
    }

    return result
  }
}
