import { ShortInfo as ShortSaleIntl } from '@stockpulse/typescript-axios/types'

export interface ShortSaleUs {
    market: string,
    date: number,
    shortExemptVolume: number,
    totalVolume: number,
    shortVolume: number
}

export type ShortSaleInfos = ShortSaleUs[] | ShortSaleIntl[]

export type ShortSalesChartData = {
    series: Array<{
        name: string,
        data: [ number, number ][]
    }>
    inPercentage: boolean
}

export function isShortSaleUsArray (data: ShortSaleInfos|undefined): data is ShortSaleUs[] {
  return Array.isArray(data) && data.length > 0 && (data as unknown[]).every(item => {
    const us = item as Partial<ShortSaleUs>
    return 'market' in us &&
      'date' in us &&
      'shortExemptVolume' in us &&
      'totalVolume' in us &&
      'shortVolume' in us
  })
}

export function isShortInfoIntlArray (data: ShortSaleInfos|undefined): data is ShortSaleIntl[] {
  return Array.isArray(data) && data.length > 0 && (data as unknown[]).every(item => {
    const intl = item as Partial<ShortSaleIntl>
    return 'date_short' in intl &&
      'isin' in intl &&
      'shares_percent_short' in intl &&
      'short_exempt_volume' in intl &&
      'short_volume' in intl &&
      'total_volume' in intl
  })
}
