export function useBufferedThrottleFn<T> (bufferedUpdate: (values: T[]) => void, waitBetweenMs: number)
  : (values: T[]) => void {
  let lastExecTs = 0
  let timeoutId: ReturnType<typeof setTimeout> | undefined
  let buffer: T[] = []

  return (argsArray: T[]): void => {
    buffer.push(...argsArray)
    const now = Date.now()
    const elapsed = now - lastExecTs

    const invoke = (): void => {
      lastExecTs = Date.now()
      const currentBuffer = [...buffer]
      buffer = []
      bufferedUpdate(currentBuffer)
    }

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId)
    }
    if (lastExecTs === 0 || elapsed > waitBetweenMs) {
      invoke()
    } else {
      timeoutId = setTimeout(invoke, waitBetweenMs - elapsed)
    }
  }
}
