import { store } from 'quasar/wrappers'
import { createPinia } from 'pinia'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store((/* { ssrContext } */) => {
  // use pinia boot file instead of store index file.
  // Otherwise, registered plugins break if using a store in other boot files
  // https://github.com/quasarframework/quasar/discussions/12736

  return createPinia()
})
