import { date, useQuasar } from 'quasar'
import { useI18n } from 'vue-i18n'
import { add, Duration, endOfDay, startOfDay, sub } from 'date-fns'

export function period (startDate: Date, endDate: Date): string {
  const $q = useQuasar()
  const { t } = useI18n()

  // Check if it's a whole day
  if (
    date.formatDate(startDate, 'YYYY-MM-DD') === date.formatDate(endDate, 'YYYY-MM-DD') &&
    date.formatDate(startDate, 'HHmmss') === '000000' && date.formatDate(endDate, 'HHmmss') === '235959'
  ) {
    // Check for today
    if (date.formatDate(new Date(), 'YYYY-MM-DD') === date.formatDate(startDate, 'YYYY-MM-DD')) {
      return t('today')
    } else {
      return new Intl.DateTimeFormat($q.lang.isoName, { dateStyle: 'short' }).format(startDate)
    }
  }

  // Check for ending today
  if (date.formatDate(new Date(), 'YYYY-MM-DD') === date.formatDate(endDate, 'YYYY-MM-DD')) {
    // TODO: Translate
    // TODO: Add timezone

    // Check for starting today
    if (date.formatDate(date.addToDate(new Date(), { days: -1 }), 'YYYY-MM-DD') ===
      date.formatDate(startDate, 'YYYY-MM-DD')) {
      return 'yesterday ' + new Intl.DateTimeFormat($q.lang.isoName, {
        timeStyle: 'short'
      }).format(endDate) + ' - ' +
        ' today ' + new Intl.DateTimeFormat($q.lang.isoName, { timeStyle: 'short' }).format(startDate)
    } else {
      return new Intl.DateTimeFormat($q.lang.isoName, {
        dateStyle: 'short',
        timeStyle: 'short'
      }).format(endDate) + ' - ' +
        ' today ' + new Intl.DateTimeFormat($q.lang.isoName, { timeStyle: 'short' }).format(startDate)
    }
  } else {
    // TODO: Add timezone
    return new Intl.DateTimeFormat($q.lang.isoName, { dateStyle: 'short', timeStyle: 'short' })
      .format(startDate) + ' - ' +
      new Intl.DateTimeFormat($q.lang.isoName, { dateStyle: 'short', timeStyle: 'short' })
        .format(endDate)
  }
}

export interface RelativeTimeRange {
    name: string
    duration: Duration
}

export interface AbsoluteTimeRange {
    startDate: Date
    endDate: Date
}

// Ensure exclusive OR - no overlapping properties
// https://github.com/Microsoft/TypeScript/issues/14094#issuecomment-373782604
type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = (T | U) extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

export type AbsoluteOrRelativeTimeRange = XOR<AbsoluteTimeRange, RelativeTimeRange>

export function getAbsoluteTimeRangeFromRelativeTimeRange (timeRange: RelativeTimeRange): AbsoluteTimeRange {
  const now = endOfDay(new Date())
  const startDate = calculateStartDate(now, timeRange.duration)

  return { startDate, endDate: now }
}

export function calculateStartDate (endDate: Date, duration: Duration): Date {
  const startDate = add(sub(endDate, duration), { days: 1 })
  if (duration.days || duration.weeks || duration.months || duration.years) {
    return startOfDay(startDate)
  }

  if (duration.hours !== undefined) {
    endDate = new Date()
    return sub(endDate, duration)
  }

  return startDate
}

export function isRelativeTimeRange (obj: AbsoluteOrRelativeTimeRange): obj is RelativeTimeRange {
  return typeof obj === 'object' && 'duration' in obj
}
