export enum ListEntityType {
    LIST = 'list',
    SECTOR = 'sector',
    WATCHLIST = 'watchlist',
    EXCHANGE = 'exchange',
    REGION = 'region',
    COUNTRY = 'country',
    ASSET = 'asset',
    KEYEVENT = 'keyEvent',
    TYPE = 'type'
}
