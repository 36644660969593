<template>
  <q-dialog
    ref="dialogRef"
    class="col-8"
    @hide="onDialogHide"
    square
  >
    <q-card>
      <q-card-section class="row content-center justify-center">
        <q-icon
          class="self-center"
          v-if="icon"
          :name="icon"
          color="primary"
          text-color="white"
          size="md"
        />
        <span class="q-mt-md">{{ text }}</span>
      </q-card-section>
      <q-card-section
        v-if="additionalInfo !== undefined"
        class="text-bold"
      >
        {{ additionalInfo }}
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          class="col-grow"
          :label="acceptText"
          color="primary"
          @click="onOKClick"
          square
          unelevated
        />
        <q-btn
          :label="declineText"
          color="secondary"
          @click="onDialogCancel"
          v-close-popup
          square
          unelevated
        />
      </q-card-actions>
      <q-inner-loading :showing="loading">
        <q-spinner-dots
          color="primary"
          size="3em"
        />
      </q-inner-loading>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { QDialog, useDialogPluginComponent } from 'quasar'

defineEmits([
  ...useDialogPluginComponent.emits
])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  acceptFunction: {
    type: Function,
    required: false,
    default: undefined
  },
  icon: {
    type: String,
    required: false,
    default: undefined
  },
  acceptText: {
    type: String,
    required: false,
    default: 'Remove'
  },
  declineText: {
    type: String,
    required: false,
    default: 'Cancel'
  },
  additionalInfo: {
    type: String,
    required: false,
    default: undefined
  }
})
const loading = ref(false)

async function onOKClick (): Promise<void> {
  loading.value = true
  if (props.acceptFunction) {
    await props.acceptFunction()
  }
  loading.value = false
  onDialogOK()
}

</script>
