import { computed, Ref, ref } from 'vue'
import axios from 'axios'

export interface AsyncApiData<T> {
  loading: boolean;
  value: T|undefined;
  error: boolean;
  errorObj: unknown|undefined;
  httpStatus: number|undefined,
  cancelled: boolean;
  reload: (() => void)|undefined
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAsyncApiData<T> (
  globalLoading: Ref<boolean>|undefined = undefined,
  initialValue: T|undefined = undefined
) {
  const internalState = ref<AsyncApiData<T>>({
    loading: true,
    value: initialValue,
    error: false,
    errorObj: undefined,
    httpStatus: undefined,
    cancelled: false,
    reload: undefined
  }) as Ref<AsyncApiData<T>>

  const state = computed<AsyncApiData<T>>(() => {
    return {
      ...internalState.value,
      loading: internalState.value.loading || (globalLoading?.value ?? false)
    }
  })

  const currentRunSequence: Ref<number> = ref(0)

  function load (loader: ((() => Promise<T|undefined>)|(() => T|undefined))): Promise<void> {
    const runSequence = ++currentRunSequence.value
    internalState.value = {
      loading: true,
      value: undefined,
      error: false,
      errorObj: undefined,
      httpStatus: undefined,
      cancelled: false,
      reload: (): Promise<void> => load(loader)
    }

    return Promise.resolve(loader()).then((result: T|undefined): void => {
      if (runSequence === currentRunSequence.value) {
        internalState.value = {
          loading: false,
          value: result,
          error: false,
          errorObj: undefined,
          httpStatus: 200,
          cancelled: false,
          reload: (): Promise<void> => load(loader)
        }
      }
    })
      .catch((err: unknown): void => {
        if (runSequence === currentRunSequence.value) {
          internalState.value = {
            loading: axios.isCancel(err),
            value: undefined,
            error: !axios.isCancel(err),
            errorObj: err,
            cancelled: axios.isCancel(err),
            httpStatus: axios.isAxiosError(err) ? err.status : undefined,
            reload: (): Promise<void> => load(loader)
          }
        }
      })
  }

  return {
    load,
    state
  }
}

export function defaultLoadingAsyncApiData<T> (t: T|undefined) : AsyncApiData<T> {
  return {
    loading: true,
    value: t,
    error: false,
    errorObj: undefined,
    httpStatus: undefined,
    cancelled: false,
    reload: undefined
  }
}
