import { route } from 'quasar/wrappers'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory, isNavigationFailure,
  RouteLocationNormalized
} from 'vue-router'

import routes, {
  ROUTE_NAME_ACCOUNTS,
  ROUTE_NAME_HOME,
  ROUTE_NAME_LOGIN,
  ROUTE_NAME_PURCHASE,
  ROUTE_NAME_REGISTER,
  routeRequiresAuthorization
} from './routes'
import { Platform } from 'quasar'
import { useAuthUserStore } from 'stores/auth-user-store'
import { useHistoryStore } from 'stores/history-store'
import { axiosRequestManager } from 'boot/axios'
import { SubscriptionEnum } from 'src/services/PermissionService'
import { ActionType } from 'src/services/AxiosRequestManager'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const router = createRouter({
    scrollBehavior: () => {
      return new Promise((resolve) => {
        resolve({ left: 0, top: 0, behavior: 'smooth' })
      })
    },
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })

  // Add referrer to every request
  router.beforeEach(async (to, from) => {
    Object.defineProperty(
      document,
      'referrer',
      {
        configurable: true,
        value: from.fullPath ? window.location.origin + from.fullPath : ''
      }
    )
  })

  // Login guard
  router.beforeEach(async (to, from, next) => {
    const authStore = useAuthUserStore()
    if (authStore.isLoggedIn && !authStore.hasValidSubscription && routeRequiresAuthorization(String(to.name))) {
      next({ name: ROUTE_NAME_ACCOUNTS })
    } else if (routeRequiresAuthorization(String(to.name)) && !authStore.isLoggedIn) {
      next({ name: ROUTE_NAME_LOGIN, query: { redirect: to.fullPath } })
    } else if (authStore.isLoggedIn && [ROUTE_NAME_REGISTER, ROUTE_NAME_LOGIN].includes(String(to.name))) {
      next({ name: ROUTE_NAME_HOME })
    } else if (
      authStore.isLoggedIn &&
      authStore.user.subscription_type !== SubscriptionEnum.TRIAL_USER &&
      String(to.name) === ROUTE_NAME_PURCHASE
    ) {
      next({ name: ROUTE_NAME_HOME })
    } else {
      next()
    }
  })

  // collect navigation history
  router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized, failure) => {
    if (isNavigationFailure(failure)) {
      return
    }

    if (to.name) {
      const historyStore = useHistoryStore()
      historyStore.addHistoryEntry(to)
    }
  })

  router.afterEach((to, from, failure) => {
    if (failure && !isNavigationFailure(failure)) {
      console.error('Router navigation failure', failure)
    }
  })

  router.beforeEach((to, from) => {
    if (from.name !== ROUTE_NAME_LOGIN && from.name !== to.name) {
      axiosRequestManager.cancelRequests(ActionType.ChangePage)
    }
  })

  router.afterEach(async (to) => {
    if (Platform.within.iframe) {
      // TODO: remove? We do not need iframe
      // TODO: Restrict to https://stockpulse.ai
      // const targetOrigin = 'https://stockpulse.ai'
      const targetOrigin = '*'

      window.parent.postMessage({ type: 'navigation', data: { to: to.fullPath } }, targetOrigin)
    }
  })

  return router
})
