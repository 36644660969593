import { differenceInDays, differenceInHours, Duration } from 'date-fns'
import ConfirmDialogComponent from 'components/ConfirmDialogComponent.vue'
import { DialogChainObject, QDialogOptions } from 'quasar'
import { trackClick, TrackingAction } from 'boot/plausible'
import { timeRangeStore } from 'stores/time-range-store'
import { i18n } from 'boot/i18n'
import {
  AbsoluteOrRelativeTimeRange,
  AbsoluteTimeRange,
  isRelativeTimeRange,
  RelativeTimeRange
} from 'src/helper/DateTimeHelper'

export default class TimeRangeSelectionService {
  // Show confirmation dialog if time period is longer than ... days
  private readonly RANGE_LIMIT_FOR_CONFIRMATION = 31

  private readonly openDialog: (opts: QDialogOptions) => DialogChainObject
  private readonly timeRangeStore = timeRangeStore()
  private readonly componentIdentifier: string
  private readonly locale: string
  private readonly compactNumberFormatter: Intl.NumberFormat

  constructor (componentIdentifier: string, openDialog: (opts: QDialogOptions) => DialogChainObject, locale?: string) {
    this.componentIdentifier = componentIdentifier
    // useQuasar() can only be called in setup of a component
    this.openDialog = openDialog
    this.locale = locale ?? 'en-US'
    this.compactNumberFormatter = new Intl.NumberFormat(this.locale, { notation: 'compact' })
  }

  setTimeSelection (
    timeSelection: AbsoluteOrRelativeTimeRange,
    confirmInfoCallback: undefined | (() => (number|undefined)) = undefined
  ): void {
    if (this.showConfirmationDialog(timeSelection)) {
      const approximatedNumberOfMessages = confirmInfoCallback?.()
      this.openConfirmDialog(timeSelection, approximatedNumberOfMessages)
      return
    }
    this.setTimeSelectionInStore(timeSelection)
  }

  private showConfirmationDialog (timeSelection: AbsoluteOrRelativeTimeRange): boolean {
    if (isRelativeTimeRange(timeSelection)) {
      return getDurationInDays(timeSelection.duration) > this.RANGE_LIMIT_FOR_CONFIRMATION
    }
    return differenceInDays(timeSelection.endDate, timeSelection.startDate) > this.RANGE_LIMIT_FOR_CONFIRMATION
  }

  private openConfirmDialog (
    timeSelection: AbsoluteOrRelativeTimeRange,
    approximatedNumberOfMessages: number | undefined
  ): void {
    this.openDialog({
      component: ConfirmDialogComponent,
      componentProps: {
        text: i18n.global.t('TimeRangeSelectionService.confirm.text'),
        acceptText: i18n.global.t('TimeRangeSelectionService.confirm.continueButton'),
        additionalInfo: approximatedNumberOfMessages !== undefined
          ? i18n.global.t(
            'TimeRangeSelectionService.confirm.additionalInfo',
            { messageCount: this.compactNumberFormatter.format(approximatedNumberOfMessages) }
          )
          : undefined,
        icon: 'spi-clock',
        acceptFunction: () => this.setTimeSelectionInStore(timeSelection)
      }
    })
  }

  private setTimeSelectionInStore (timeSelection: AbsoluteOrRelativeTimeRange): void {
    if (isRelativeTimeRange(timeSelection)) {
      return this.setRelativeTimeRangeInStore(timeSelection)
    }

    this.setAbsoluteTimeRangeInStore(timeSelection)
  }

  private setRelativeTimeRangeInStore (timeRange: RelativeTimeRange): void {
    this.timeRangeStore.setRelativeTimeRange(timeRange)

    const duration = 365 * (timeRange.duration.years || 0) + 31 * (timeRange.duration.months || 0) +
      (timeRange.duration.days || 0) > 0
      ? 365 * (timeRange.duration.years || 0) + 31 * (timeRange.duration.months || 0) +
        (timeRange.duration.days || 0) + ' days'
      : (timeRange.duration.hours || 0) + ' hours'

    trackClick(TrackingAction.SELECT_TIME_RANGE, this.componentIdentifier, { timeSpan: timeRange.name, duration })
  }

  private setAbsoluteTimeRangeInStore (timeRange: AbsoluteTimeRange): void {
    this.timeRangeStore.setAbsoluteTimeRange(timeRange)

    const duration = differenceInDays(timeRange.endDate, timeRange.startDate) > 0
      ? differenceInDays(timeRange.endDate, timeRange.startDate) + ' days'
      : differenceInHours(timeRange.endDate, timeRange.startDate) + ' hours'

    trackClick(
      TrackingAction.SELECT_TIME_RANGE,
      this.componentIdentifier,
      { timeSpan: timeRange.startDate.toISOString() + ' - ' + timeRange.endDate.toISOString(), duration }
    )
  }
}

export function getDurationInDays (duration: Duration): number {
  return 365 * (duration.years || 0) + 31 * (duration.months || 0) + (duration.days || 0) +
    // Add one day if hours, minutes or seconds is not empty
    Math.sign((duration.hours || 0) + (duration.minutes || 0) + (duration.seconds || 0))
}
