import { Router } from 'vue-router'
import { Notify, QNotifyCreateOptions } from 'quasar'
import { ROUTE_NAME_ACCOUNTS } from 'src/router/routes'
import { QNotifyAction } from 'quasar/dist/types/api'

export default class NotificationService {
  private router: Router
  private DEFAULT_DISPLAY_TIME = 5000

  constructor (router: Router) {
    this.router = router
  }

  notify (notificationOptions: QNotifyCreateOptions|string, origin?: string[]): void {
    // The path includes /
    if (!origin || origin.includes(this.router.currentRoute.value.path.slice(1))) {
      if (typeof notificationOptions === 'object' && notificationOptions.timeout === undefined) {
        notificationOptions.timeout = this.DEFAULT_DISPLAY_TIME
      }

      Notify.create(notificationOptions)
    }
  }

  notifySoonExpiration (validUntil: number, hasPendingPayment: boolean): void {
    let message = 'Your subscription expires soon.'
    const actions : QNotifyAction[] = [
      { label: 'Dismiss', color: 'white', handler: () : void => { /* ... */ } }
    ]
    if (hasPendingPayment) {
      message += ' Please complete the payment to activate your account and unlock the full features.'
    } else {
      actions.unshift(
        {
          label: 'Upgrade',
          handler: (): void => {
            this.router.push({ name: ROUTE_NAME_ACCOUNTS })
          }
        }
      )
    }
    this.notify({
      message,
      caption: 'Expires: ' + (new Date(validUntil * 1000).toDateString()),
      actions,
      type: 'warning',
      timeout: 0,
      position: 'top-right',

      icon: 'spi-triangle-exclamation'
    })
  }

  notifySubscriptionExpired (): void {
    this.notify({
      message: 'Yours subscription has expired.',
      caption: 'To continue using Stockpulse, please purchase a new subscription.',
      actions: [
        {
          label: 'Ok',
          handler: () : void => { /* ... */ }
        }
      ],
      type: 'warning',
      timeout: 0,
      position: 'top-right',
      icon: 'spi-triangle-exclamation'
    })
  }
}
