import { TrackingAction } from 'src/boot/plausible'
import { RouteLocationRaw } from 'vue-router'
import { ROUTE_NAME_VIEW } from 'src/router/routes'
import { AssetEntity } from 'src/entities/AssetEntity'
import { Feature } from 'src/services/PermissionService'
import { useInventoryStore } from 'stores/inventory-store'

export class IndexEntity extends AssetEntity {
  protected readonly defaultIcon: string = 'spi-chart-line'
  protected readonly requiredFeatures : Feature[] = ['displayLists']
  public getIcon (): string {
    return useInventoryStore().getListById(this.id)?.icon || this.defaultIcon
  }

  public getTrackingAction (): TrackingAction {
    return TrackingAction.OPEN_VIEW
  }

  public getRouteOptions (): RouteLocationRaw {
    return { name: ROUTE_NAME_VIEW, query: { list: [this.id] } }
  }
}
