import { LocationQuery, RouteLocationNamedRaw, RouteLocationRaw, Router } from 'vue-router'
import { RouteListViewProps } from 'src/router/routes'
import { ViewFilter } from 'boot/axios'

export async function replaceCurrentQueryParams (router: Router, query: LocationQuery, hash?: string) : Promise<void> {
  const currentQuery = router.currentRoute.value.query
  const newQuery = { ...currentQuery, ...query }
  for (const prop in newQuery) {
    if (newQuery[prop] === null) {
      delete newQuery[prop]
    }
  }
  const route = { ...router.currentRoute.value, query: newQuery }
  if (hash !== undefined) {
    route.hash = hash
  }
  await router.replace(route)
}

export function getViewFilterFromProps (props : RouteListViewProps):ViewFilter {
  return {
    keyEvent: props.keyEvent,
    asset: props.asset,
    type: props.type,
    country: props.country,
    list: props.list,
    exchange: props.exchange,
    sector: props.sector,
    region: props.region
  }
}

export function instanceOfRouteLocationNamedRaw (obj: RouteLocationRaw): obj is RouteLocationNamedRaw {
  return typeof obj === 'object' && 'query' in obj
}
