/* eslint-disable max-len */

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  yesterday: 'yesterday',
  today: 'today',
  empty: {
    messages: 'No messages found',
    'key-events': 'No key events found.',
    'referenced-people': 'No referenced people found.',
    topics: 'No topics found.'
  },
  days: 'days',
  month: 'month',
  upgradeAccount: 'Upgrade',
  awaitPaymentBanner: 'You are currently using a trial account. Please complete the payment to activate your account and unlock the full features.',
  trialBanner: 'You are currently using a trial account. To continue using Stockpulse, please upgrade your plan.',
  upgradeBannerAction: 'Upgrade now',
  premiumSubscriptionRequired: 'You need at least the Premium subscription to use this view',
  RegisterPage: {
    trialSubmit: 'Create trial account',
    trialHeadline: 'Trial account',
    thanksForInterest: 'Thank you for your interest in Stockpulse!',
    successCaption: 'We have created a trial account for you!',
    successCaption2: 'You have received an email with further information.',
    successCaption3: '(Please also check your spam folder if you have not received an email).'
  },
  PurchasePage: {
    requiredFields: '* mandatory fields',
    runtime: 'Runtime',
    pricePerMonth: 'Price per month',
    amount: 'Amount',
    taxIncluded: 'Incl. Tax',
    alreadyGotAccount: 'Already have an account?',
    linkToLogin: 'Log in here',
    FirstStep: {
      name: 'Contact details',
      submitLabel: 'Continue to billing address'
    },
    SecondStep: {
      name: 'Invoice data',
      submitLabel: 'Continue to payment'
    },
    ThirdStep: {
      name: 'Overview & Payment ',
      invoice: 'Invoice',
      buy: 'Buy now',
      getStarted: 'Get started',
      buyInvoice: 'Buy by invoice',
      buyPaypal: 'Buy with PayPal',
      yearlyNote: 'The annual fee is billed at the beginning of the term. If no notice of termination is given, the subscription will be extended at the end of the first contract year for the same period at the regular list price.',
      monthlyNote: 'The monthly fee is billed at the beginning of the term. If no notice of termination is given, the subscription will be extended at the end of the month for the same period at the regular list price.',
      paymentMethod: '2) Payment Method',
      immediateFullAccess: 'Using this payment method gives you instant access to all features.',
      notAvailable: 'It is currently not possible to pay via invoice for a monthly subscription. Please choose yearly subscription to use the payment option via invoice. Thank you.',
      accessAfterPayment: 'You will be able to continue to use the platform as a trial user. After we registered your payment, we will upgrade your account for use of all features of your purchased subscription.',
      termsAndConditionHeadline: '1) Terms & conditions',
      termsAndCondition: 'I accept the terms and conditions as stated in the product sheet on the right. The General Terms and Conditions apply.'
    },
    SuccessScreen: {
      payPalHeadline: 'Order successful!',
      payPalBody: 'Your payment has been received and you can now use Stockpulse.',
      invoiceHeadline: 'Your order has been placed successfully!',
      invoiceBody: 'Please follow the instructions sent to you via e-mail.',
      invoiceBody2: 'Your subscription will be activated upon receipt of your payment. Until then, we have created a trial account for you.'
    }
  },
  StartPage: {
    ImportantNews: {
      headline: 'Important News',
      note: 'The widget lists recent important news related to the given market.'
    },
    Tutorials: {
      headline: 'Tutorials'
    },
    LatestAlerts: {
      headline: 'Latest Alerts'
    },
    MarketOverview: {
      yourMarkets: 'Your Markets',
      otherMarkets: 'Other Markets'
    }
  },

  EditFilterDialogComponent: {
    headline: 'Edit View',
    accept: 'Apply',
    cancel: 'Cancel'
  },

  EditViewDialogComponent: {
    headline: 'Edit View'
  },

  PayPalErrorDialogComponent: {
    headline: 'Error processiong order',
    body: 'An error has occurred while processing your order via PayPal. This may be a temporary problem with the PayPal servers. Our technicians have been notified and will contact you if necessary.',
    accept: 'Ok'
  },

  Subscriptions: {
    IntervalUnit: {
      MONTH: 'Month',
      YEAR: 'Year'
    },
    FeatureCategories: {
      universeCoverage: 'Universe & Coverage',
      smartFilters: 'Smart Filters',
      timeHistory: 'Time & History',
      aiLLM: 'AI & LLM Services',
      tools: 'Tools',
      alerts: 'Alerts',
      accessLicenses: 'Access & Licenses'
    },
    Features: {
      AiAlerts: {
        headline: 'Watchlist AI Alerts',
        note: 'Alerts based significant buzz levels optimized with AI for all stocks that were added to the watchlist'
      },
      AuthorView: {
        headline: 'Authors view',
        note: 'Open a detailed view on a social media author and all corresponding statistics'
      },
      BuzzAlerts: {
        headline: 'Rule Based Buzz Alerts',
        note: 'Alerts for all assets based on pre-defined buzz and sentiment alerts with instant notification'
      },
      CombineFilter: {
        headline: 'Combine multiple filters',
        note: 'Combine several filters to narrow down the search for specific messages, topics, or time periods'
      },
      Download: {
        headline: 'Download options',
        note: 'Download of key indicator values such as buzz or sentiment in CSV format to use in external programs'
      },
      EbsPatterns: {
        headline: 'Pre-Earnings Buzz Alerts',
        note: 'Alerts for all assets based on high buzz levels before earnings dates'
      },
      FullTextSearch: {
        headline: 'Full-text search',
        note: 'Full-text search for any search query'
      },
      HottestTopics: {
        headline: 'Hottest topics',
        note: 'These are most important topics extracted with AI from latest news headlines.'
      },
      KeyEventAlerts: {
        headline: 'Key Event Alerts',
        note: 'Alerts for trending key events with instant notification'
      },
      KeyEventFilter: {
        headline: 'Filter on key events',
        note: 'Filter an asset view and all associated messages for a certain key events within the specific asset snapshot view'
      },
      KeyEventView: {
        headline: 'Key events view',
        note: 'Show detailed snapshot view on key events, e.g., which assets are mostly associated with a certain key event'
      },
      LanguageFilter: {
        headline: 'Filter on languages',
        note: 'Filter on languages for messages or posts'
      },
      LicensedUsers: {
        headline: 'Licensed users',
        note: 'Number of allowed users'
      },
      ListView: {
        headline: 'Indices, lists of assets',
        note: 'Possibility to look at detailed views for indices or lists of assets'
      },
      ListViewNothingFound: {
        headline: 'No Assets found for selected filters',
        hint: 'Use the searchbar'
      },
      MaxHistoryMonth: {
        headline: 'Max history search',
        note: 'Maximum time period for historical searches'
      },
      MaxTimePeriod: {
        headline: 'Max time period',
        note: ''
      },
      MessageTypeFilter: {
        headline: 'Filter on message types',
        note: 'Filter on different types of messages or posts, e.g., news, social media, forum, blog, adhoc, etc.'
      },
      MorningBriefing: {
        headline: 'Reports/Morning Briefing*',
        note: 'Daily reports for different categories, e.g., most discussed stocks from a certain region or sector'
      },
      Price: {
        headline: 'Price',
        note: 'Price of subscription'
      },
      PriceBuzzAlerts: {
        headline: 'Price/Buzz Alerts',
        note: 'Alerts for stocks that show significant price developments along with significant buzz levels'
      },
      Realtime: {
        headline: 'Real time/intraday',
        note: 'Get real time updates on assets and lists in the snapshot view'
      },
      SourceFilter: {
        headline: 'Filter on sources',
        note: 'Filter on specific data sources and see detailed analysis'
      },
      SubscriptionPeriod: {
        headline: 'Subscription period',
        note: 'Length of subscription'
      },
      Summaries: {
        headline: 'AI Executive summaries',
        note: 'Generation of AI news summaries for all assets and display on snapshots',
        static: 'static/delayed',
        dynamic: 'dynamic/live generated'
      },
      Support: {
        headline: 'Support',
        note: 'Contact Stockpulse via email with specific questions and requests'
      },
      TitleView: {
        headline: 'All single assets',
        note: 'Search option and snapshot view for all single assets such as stocks, cryptocurrencies, currency pairs, or commodities'
      },
      Watchlist: {
        headline: 'Watchlist',
        note: 'Creation of own watchlist for stocks, indices, and lists'
      }
    }
  },

  CreateAccountComponent: {
    LoginHint: 'There is already an account for your username.',
    LoginHint2: 'If you want to upgrade your account or purchase a subscription, please log in first. You can log in',
    rule: 'Please enter your first name',
    Form: {
      salutation: {
        label: 'Salutation'
      },
      firstName: {
        label: 'First name',
        error: 'Please enter your first name'
      },
      surname: {
        label: 'Surname',
        error: 'Please enter your surname'
      },
      email: {
        label: 'Email',
        error: 'Please enter your email address'
      },
      password: {
        label: 'Password',
        error: 'Please enter your email address'
      },
      termsAndCondition: {
        error: 'Please confirm our terms and conditions'
      }
    }
  },
  AccountCardComponent: {
    bestseller: 'Bestseller',
    monthly: 'per month',
    singleUser: 'Single User',
    showDetails: 'Show all details'
  },

  AccountsPage: {
    headline: '.AI improves your decisions! Don’t hesitate.',
    getInTouch: 'Get in touch',
    trialText: 'It is free and there is no obligation to subscribe after the trial.',
    enterpriseSolution: 'Our Enterprise Solution',
    enterpriseSolutionText: 'If you are looking for more licenses, more history, specific data sets, or if you have specific requirements that you cannot find in our standard account offerings please reach out to us.',
    pdfReport: 'Our PDF Reports',
    pdfReportText: 'Our daily PDF reports, also called “Morning Briefings” provide a quick overview of the most discussed entities. We generate more than 70 (?) Morning Briefings for different regions, markets, key events, or sectors.',
    pdfReportText2: 'All reports contain more detailed information, such as concrete social media messages or news, sources, etc.',
    pdfReportText3: 'You can subscribe to Morning Briefings with the Platinum and Professional subscriptions.'
  },

  OfferingTableComponent: {
    comparison: 'Comparison of our account offerings'
  },

  DisplayTitleFilterComponent: {
    headline: 'Filter',
    note: 'The filter status box shows the current filtering of the selected search input. Individual filters can also be removed. Searchable entities can be opened in a new view by clicking on them. Each additional filter displays a subset of assets of the original selection.',
    remove: 'Remove filter',
    removeAll: 'Remove all filters'
  },

  TitleFilter: {
    allAssets: 'All Assets'
  },

  MessageFilterMenu: {
    applyFilter: 'Apply Filter',
    clearFilter: 'Clear Filter',
    editButton: 'Edit Message Filter'
  },

  MessagesListComponent: {
    requestOutOfRange: 'You are requesting data older than your subscription allows.',
    noMoreMessagesFound: 'No more messages were found.'

  },

  DistributionComponent: {
    removeFilter: 'Remove filter'
  },

  PremiumFeatureDialog: {
    headline: 'Upgrade your account',
    text: 'Get access to more features by upgrading your account.',
    pendingPayment: 'Please complete the payment to activate your account and unlock the full features.',
    callToAction: 'Please contact '
  },

  LatestEventsComponent: {
    headline: 'Latest Events',
    note: 'These signals are based on general rules based on short term buzz and sentiment changes. For example if an asset crosses a certain buzz level or if it shows significant sentiment changes.',
    'no-signals-found': 'No Signals found',
    cta: 'No new events | Show new event | Show {count} new events'
  },
  LoadDataWithSelectedPeriod: 'Load data with selected period',

  MobileLoadDataWithSelectedPeriod: 'Reload',
  SearchPage: {
    period: {
      last_week: 'Last week',
      last_month: 'Last month',
      last_year: 'Last year',
      any_time: 'Any time'
    },
    moreDataButton: {
      label: 'Want more history or real-time data?'
    },
    disclaimer: {
      title: 'Disclaimer',
      priceIndicationInfo: 'All stock prices shown on this page are based on Stockpulse price indications.',
      text: 'Stock exchange transactions, particularly forward, future, and options trading imply risks which can lead to a total loss of\n' +
        'your entire capital employed and other losses. Get professional and detailed advice from your bank about relevant loss risks\n' +
        'before making your investment decisions.\n' +
        'The information and data available on this page are only for information purposes. Stockpulse does\n' +
        'neither provide investment consulting or investment brokerage nor the call of buying or selling stocks, forwards, futures, or\n' +
        'options. Despite extraordinary diligence Stockpulse cannot take responsibility for the\n' +
        'correctness, accurateness, or completeness of the information presented here. Each stock\n' +
        'volatility prediction as well as all given trading signals represent the exclusive opinion of Stockpulse, do not contain any\n' +
        'responsibility, do not represent any promises on future earnings, and are no call for imitation not even by implication.\n' +
        'Earnings realized in the past do not guarantee future earnings. This especially applies for trading signals. We explicitly point\n' +
        'out that it is possible that the suggestions generated by Stockpulse are not necessarily happening in real stock exchange\n' +
        'trading. We exclude liability for financial losses.\n' +
        'Stockpulse is not responsible for any loss arising from any investment based on a perceived recommendation.\n' +
        'Everybody who is using and registering for Stockpulse accepts the terms and conditions of the Stockpulse GmbH. Visiting\n' +
        'the Stockpulse Websites does not automatically imply a customer relationship.\n' +
        'There are no responsibilities, commitment, or liability with respect to persons or organizations.\n' +
        'The statements on this page shall not be considered as an objective or independent explanation of the matters. Please\n' +
        'note that this page (a) has not been prepared in accordance with legal requirements designed to promote the\n' +
        'independence of investment research, and (b) is not subject to any prohibition on dealing ahead of the dissemination or\n' +
        'publication of investment research.'
    }
  },
  ContactSalesDialogComponent: {
    title: 'Are you interested?',
    email: {
      label: 'Email',
      placeholder: 'Your email',
      validation: {
        error: 'Please enter a valid email address'
      }
    },
    firstname: {
      label: 'Firstname',
      placeholder: 'Your firstname'
    },
    lastname: {
      label: 'Lastname',
      placeholder: 'Your lastname'
    },
    message: {
      label: 'Message',
      placeholder: 'Your message'
    },
    submit: {
      error: 'Error submitting form'
    }
  },
  SearchComponent: {
    SearchQuery: {
      placeholder: 'Search any financial topic…'
    },
    Submit: {
      label: 'Search'
    }
  },
  TrendingTitlesComponent: {
    headline: {
      topSelection: 'Trending Assets',
      indexes: 'Trending Indexes',
      marketUpdate: 'Market Update',
      trendingIndexes: 'Trending Indexes',
      trendingGlobal: 'Trending Global'
    }
  },
  TrendingKeyEvents: {
    headline: 'Trending Key Events'
  },
  TimeRangeSelectionService: {
    confirm: {
      text: 'Please note: Loading the data for this period may take some time, possibly a few minutes.',
      continueButton: 'Continue',
      additionalInfo: 'The search result will contain no messages. | The search result will contain approx. 1 message. | The search result will contain approx. {messageCount} messages.'
    }
  },
  AuthorView: {
    headline: {
      impactScore: 'Impact Score',
      messages: 'Messages',
      botScore: 'Bot Likeliness Score',
      authorHistoryChartComponent: 'Previous Message Distribution',
      authorMessageStatistics: 'Message Statistics',
      sameAuthorNameOtherSources: 'Same Author Name in other sources',
      titlesMentioned: 'Previous Mentioned Assets',
      assetDistribution: 'Mentioned Assets'
    },
    note: {
      impactScore: 'The Impact Score of an author or user can range from 0 to 5. 0 means the author was classified as bot and will be on a blacklist. A score of 5 means that these authors are most credible. All authors with a score of 5 are manually curated. There is an automatic ranking of scores between 1 and 4. This depends on the source, because there are different data points for every source. E.g. for X/Twitter we know the number of followers, following, the creation date of the account and the number of tweets. Based on these metrics it is possible to calculate a score which will then be represented in the Impact Score.',
      authorMessageStatistics: 'The message statistics for a single author show how active the author was recently and in the past. The number of messages per time interval provide an impression of the activity of the author.',
      titlesMentioned: 'The assets mentioned box show about which assets the author mostly discusses, also over time.'
    }
  },
  AssetSnapshot: {
    headline: {
      hottestTopics: 'Hottest Topics',
      spreadsheet: 'Spreadsheet',
      latestAlerts: 'Latest Alerts'
    },
    note: {
      hottestTopics: 'These are topics that are extracted with our own AI and LLM from latest news articles. By changing the time period on the right upper side of the screen will also lead to the processing of different news that match to the set time period. Therefore the selection of topics can vary based on the selected time period.',
      'author-analytics': 'The bar chart shows the relative distribution of the authors to our author categories.',
      spreadsheet: 'The spreadsheet widget provides the option to download historical buzz and sentiment data for three time slot: minutes, hours, and daily.',
      latestAlerts: 'This shows the latest alerts for the entity in the last 30 days.'
    }
  },
  TitleMasterDataComponent: {
    headline: 'Fundamentals',
    note: 'This shows some important base data for the entity.'
  },
  ExecutivesTable: {
    dialogTitle: 'Executives of {titleName}'
  },
  SubsidiariesTable: {
    dialogTitle: 'Subsidiaries of {titleName}',
    dialogSubtitle: 'as of {year}'
  },
  BuzzSentimentTreeMap: {
    headline: 'Buzz Sentiment Map | Buzz Sentiment Map | Buzz Sentiment Map Top {count}',
    note: 'The Buzz Sentiment Map shows an aggregated overview for a list of stocks, e.g. an index or a country/region. The size of the rectangle indicates the level of buzz (the larger the higher the buzz) and the color indicates the sentiment (green means rather positive mood and red means rather negative mood).'
  },
  TitlesBuzzSentimentComponent: {
    headline: 'Buzz Sentiment | Buzz Sentiment | Buzz Sentiment Top {count}'
  },
  AssetSentimentTreeMap: {
    headline: 'Asset Sentiment Map | Asset Sentiment Map | Asset Sentiment Map Top {count}',
    note: 'The Asset Sentiment Map shows an aggregated overview for mentioned assets. The size of the rectangle indicates the number of messages and the color indicates the sentiment (green means rather positive mood and red means rather negative mood).'
  },
  KeyEventsTreeMap: {
    headline: 'Key Event Map Top 10',
    note: 'The Key Event Map shows an aggregated overview for a list of stocks, e.g. an index or a country/region. The size of the rectangle indicates the number of messages with the given key event and the color indicates the sentiment (green means rather positive mood and red means rather negative mood).'
  },
  TitleTechnicalDataComponent: {
    headline: 'Technical Data',
    note: 'This shows some important technical numbers.'
  },
  LastSignificantPriceChangesComponent: {
    headline: 'Last Significant Price Changes',
    note: 'This widget displays the highest price changes in the stock price of the selected entity.'
  },
  ShortSalesComponent: {
    headline: 'Short info',
    note: 'This displays the current short sale data for the entity.'
  },
  ShortSalesUsComponent: {
    headline: 'Short info US',
    note: 'This displays the current short sale data for the entity.'
  },
  BuzzSentimentChartComponent: {
    headline: 'Chart',
    note: 'The chart displays the buzz and sentiment values over time. The initial time period of the chart depends on the general time period for the selected entity. The time can be changed to different periods with a separate setting. This allows to zoom out and see larger time periods in order to see a bigger picture of the development of buzz and sentiment over time. The time slider at the bottom of the chart allows to narrow the time frame to a specified time.',
    showMarker: 'Show alerts',
    tooltip: {
      noMarker: 'No marker in selected time range.'
    }
  },
  WatchlistComponent: {
    headline: 'Trending in my watchlist',
    note: 'The widget shows the currently most discussed stocks and views from your own watchlist.',
    openWatchlistTooltip: 'Inspect watchlist'
  },
  VisitedPagesHistoryComponent: {
    headline: 'My page history',
    note: 'This box lists all previous searches of the current session.'
  },
  PreIPOCompaniesComponent: {
    headline: 'Pre IPO companies',
    note: 'This box shows upcoming IPOs and the current buzz level of IPO companies.'
  },
  AssetsComponent: {
    headline: 'Top Related Assets',
    note: 'The Top Related Assets widget shows those assets that are currently mostly related to the selected search query. These assets are mostly mentioned with the selected search query in the communication or the news. This also depends on the selected time period on the upper right side of the screen.'
  },
  TitleDescriptionComponent: {
    headline: 'Description',
    note: 'Basic description of the selected asset.'
  },
  MorningBriefingComponent: {
    headline: 'Morning Briefings'
  },
  UserPreferencesComponent: {
    headline: 'Preferences',
    save: 'Save'
  },
  SubscriptionOverviewComponent: {
    headline: 'Subscription overview',
    subscription: {
      headline: 'Subscription',
      type: 'Type',
      validUntil: 'Valid until',
      paidUntil: 'Paid until',
      aboMonths: 'Abo months'
    },
    features: {
      headline: 'Features'
    }
  },
  UserDataComponent: {
    Form: {
      email: {
        label: 'Email'
      },
      firstName: {
        label: 'First name',
        error: 'Please enter your first name'
      },
      lastName: {
        label: 'Last name',
        error: 'Please enter your last name'
      },
      birthday: {
        label: 'Birthday'
      },
      salutation: {
        label: 'Salutation'
      },
      language: {
        label: 'Language'
      },
      phone: {
        label: 'Phone'
      },
      street: {
        label: 'Street',
        error: 'Please enter your street'
      },
      number: {
        label: 'Number',
        error: 'Please enter your street number'
      },
      city: {
        label: 'City',
        error: 'Please enter your city'
      },
      zip: {
        label: 'Postal Code',
        error: 'Please enter the postal code'
      },
      country: {
        label: 'Country'
      }
    },
    headline: 'User data',
    subscribeToNewsletter: 'Subscribe to newsletter',
    save: 'Save'
  },
  WatchlistSettingComponent: {
    headline: 'Watchlist',
    assets: 'Assets',
    authors: 'Authors',
    ownViews: 'Own Views',
    showAllAssets: 'Display all assets in view',
    noAssetsOnWatchlist: 'There are currently no Assets on your Watchlist.',
    editAlerts: 'Edit Alerts',
    delete: 'Delete',
    noAuthorsOnWatchlist: 'There are currently no Authors on your Watchlist.',
    noViewOnWatchlist: 'There are currently no Views on your Watchlist.',
    editFilter: 'Edit filter'
  },
  MessageStatistics: {
    title: '{query}: Results',
    headline: {
      authors: 'Authors',
      buzz: 'Buzz',
      'most-mentioned-key-events': 'Key Events',
      'key-events': 'Key Events',
      languages: 'Languages',
      messages: 'Messages',
      messagesList: 'Messages',
      'referenced-people': 'Referenced People',
      sentiment: 'Sentiment',
      sources: 'Sources',
      types: 'Types',
      'top-authors': 'Top Authors',
      titles: 'Top Related Assets',
      titlesMostMentioned: 'Most Mentioned Related Assets',
      topics: 'Topics',
      'author-analytics': 'Author Analytics',
      'country-distribution': 'Asset countries'
    },
    note: {
      to: 'to',
      messages: 'Number of analyzed messages in the selected time range. Additionally, the chart displays the number of messages where the search term was found in over time (refers to our Buzz indicator). It shows the development over the selected time period.',
      messagesList: 'The widget shows all messages for the selected time period. More messages are loaded by scrolling in the messages widget. If a certain time period is of interest, the time period can be adjusted and messages for this time period will be loaded accordingly.',
      authors: 'The number represents the different authors/users who posted the messages in the selected time period.',
      sources: 'The number represents the different sources of the messages in the selected time period. Generally, we cover thousands of different sources in real time. These include traditional media/news and social media. Usually, a few large social media sources contribute most messages.',
      buzz: 'Buzz is a measure of the strength of the communication about a stock. The value is always based on the average\ncommunication about the respective stock. Average is 100%. Additionally the chart shows the buzz of the last 30 days.\n',
      sentiment: 'The sentiment represents the meaning/tonality of the content of the messages. The value/bar chart that is displayed aggregates the sentiment of the individual messages over the selected time period. In addition, the chart shows sentiment over time. It shows the development over the selected time period. In general, sentiment is based on Natural Language Processing (NLP).',
      'source-distribution': 'The pie chart shows the distribution of messages for the sources with the most news and social media posts found for the selected entity.',
      'type-distribution': 'The bar chart shows the different message types for the selected entity. The numbers represent the number of messages posted in each category. It distinguishes between these main types: tweets, comments (e.g. from sites like X, Reddit, Discord or Telegram), forum posts (from classic message boards), news (traditional news), adhoc (company announcements), and other filing categories.',
      'author-distribution': 'The bar chart shows the top authors/users with the most posts for the selected entity. The numbers represent the number of messages posted by the author in the selected time period.',
      'language-distribution': 'The bar chart shows the most frequently detected languages in the found messages. The numbers represent the number of messages in the respective language in the selected time period.',
      'key-events': 'The box shows the most frequently mentioned key events/topics (in the selected time period) detected in the related messages. The numbers in the blue bubbles represent the number of occurrences of the specified key event term in the messages.',
      'most-mentioned-key-events': 'This widget displays the most mentioned key events or topics for the asset and the specified time period. The selection of topics depends on the time period. It is possible to see the development of most mentioned topics over time which provide a very good overview of the main focus of communication about this entity.',
      'referenced-people': 'The box shows the most mentioned people/person names detected in the related messages. The numbers represent the number of occurrences of the specified people/persons in the messages.',
      'title-distribution': 'This box shows a financial instrument and the corresponding price development of the last 30 days that is closely related to the search term. For general search terms (e.g. not a specific stock or crypto currency), the most frequently mentioned financial instrument is displayed. The number of mentions are extracted from all sources Stockpulse monitors. The displayed share price is the last close price. The change value refers to the change of the last close and the close of the trading day before the last close.',
      topics: 'These topics were generated by Stockpulse\' AI. The topics are derived from recent news articles related to the search query.',
      'author-analytics': 'Authors are classified into different categories. "Regulars" are all accounts that have not shown any suspicious usage behaviour yet. "Bots" are social media accounts that show attributes of a suspicious posting behaviour. "Verified" are manually curated accounts of credible sources, e.g. such as official news sources or known and confirmed private persons and their social media accounts. "Influencers" are accounts of known influencers in the finance space. The calculation of these categories is done automatically by considering different attributes and parameters of the social media users that we have available, e.g. first appearance in a source, frequency of posting, time between different postings, volume of messages, user name patterns, etc.',
      positiveMessages: 'Amount of positive Messages',
      negativeMessages: 'Amount of negative Messages',
      titlesMostMentioned: 'The most mentioned assets widget shows those assets that are currently mostly related to the selected search query. These assets are mostly mentioned with the selected search query in the communication or the news. This also depends on the selected time period on the upper right side of the screen.',
      'country-distribution': 'The chart shows the distribution of countries for the selected entities. For companies the primary listing is relevant.'
    },
    empty: {
      messages: 'No messages found',
      'key-events': 'No key events found.',
      'referenced-people': 'No referenced people found.',
      topics: 'No topics found.'
    },
    others: 'others',
    type: {
      account: 'Account',
      adhoc: 'Adhoc',
      adv_fin_report: 'Advanced Financial Report',
      analyst: 'Analyst',
      annual_meeting: 'Annual Meeting',
      blog: 'Blog',
      buzz: 'Buzz',
      cap_mkt_info: 'Capital Market Information',
      comments: 'Comments',
      dir_dealings: 'Director Dealings',
      filings: 'Filings',
      forum_posts: 'Forum Posts',
      images: 'Images',
      nav: 'Net Asset Value Report',
      news: 'News',
      regulatory: 'Regulatory',
      short_interest: 'Short Interest',
      takeover_offer: 'Takeover Offer',
      tweets: 'Tweets',
      videos: 'Videos',
      voting_rights: 'Voting Rights',
      others: 'others'
    },
    language: {
      ab: 'Abkhazian',
      aa: 'Afar',
      af: 'Afrikaans',
      ak: 'Akan',
      sq: 'Albanian',
      am: 'Amharic',
      ar: 'Arabic',
      an: 'Aragonese',
      hy: 'Armenian',
      as: 'Assamese',
      av: 'Avaric',
      ae: 'Avestan',
      ay: 'Aymara',
      az: 'Azerbaijani',
      bm: 'Bambara',
      ba: 'Bashkir',
      eu: 'Basque',
      be: 'Belarusian',
      bn: 'Bengali',
      bi: 'Bislama',
      bs: 'Bosnian',
      br: 'Breton',
      bg: 'Bulgarian',
      my: 'Burmese',
      ca: 'Catalan, Valencian',
      ch: 'Chamorro',
      ce: 'Chechen',
      ny: 'Chichewa, Chewa, Nyanja',
      zh: 'Chinese (zh)',
      cn: 'Chinese (cn)',
      cu: 'Church Slavonic, Old Slavonic, Old Church Slavonic',
      cv: 'Chuvash',
      kw: 'Cornish',
      co: 'Corsican',
      cr: 'Cree',
      hr: 'Croatian',
      cs: 'Czech',
      da: 'Danish',
      dv: 'Divehi, Dhivehi, Maldivian',
      nl: 'Dutch, Flemish',
      dz: 'Dzongkha',
      en: 'English',
      eo: 'Esperanto',
      et: 'Estonian',
      ee: 'Ewe',
      fo: 'Faroese',
      fj: 'Fijian',
      fi: 'Finnish',
      fr: 'French',
      fy: 'Western Frisian',
      ff: 'Fulah',
      gd: 'Gaelic, Scottish Gaelic',
      gl: 'Galician',
      lg: 'Ganda',
      ka: 'Georgian',
      de: 'German',
      el: 'Greek: Modern',
      kl: 'Kalaallisut, Greenlandic',
      gn: 'Guarani',
      gu: 'Gujarati',
      ht: 'Haitian, Haitian Creole',
      ha: 'Hausa',
      he: 'Hebrew',
      hz: 'Herero',
      hi: 'Hindi',
      ho: 'Hiri Motu',
      hu: 'Hungarian',
      is: 'Icelandic',
      io: 'Ido',
      ig: 'Igbo',
      id: 'Indonesian',
      ia: 'Interlingua',
      ie: 'Interlingue, Occidental',
      iu: 'Inuktitut',
      ik: 'Inupiaq',
      ga: 'Irish',
      it: 'Italian',
      ja: 'Japanese',
      jv: 'Javanese',
      kn: 'Kannada',
      kr: 'Kanuri',
      ks: 'Kashmiri',
      kk: 'Kazakh',
      km: 'Central Khmer',
      ki: 'Kikuyu, Gikuyu',
      rw: 'Kinyarwanda',
      ky: 'Kirghiz, Kyrgyz',
      kv: 'Komi',
      kg: 'Kongo',
      ko: 'Korean',
      kj: 'Kuanyama, Kwanyama',
      ku: 'Kurdish',
      lo: 'Lao',
      la: 'Latin',
      lv: 'Latvian',
      li: 'Limburgan, Limburger, Limburgish',
      ln: 'Lingala',
      lt: 'Lithuanian',
      lu: 'Luba-Katanga',
      lb: 'Luxembourgish, Letzeburgesch',
      mk: 'Macedonian',
      mg: 'Malagasy',
      ms: 'Malay',
      ml: 'Malayalam',
      mt: 'Maltese',
      gv: 'Manx',
      mi: 'Maori',
      mr: 'Marathi',
      mh: 'Marshallese',
      mn: 'Mongolian',
      na: 'Nauru',
      nv: 'Navajo: Navaho',
      nd: 'North Ndebele',
      nr: 'South Ndebele',
      ng: 'Ndonga',
      ne: 'Nepali',
      no: 'Norwegian',
      nb: 'Norwegian Bokmål',
      nn: 'Norwegian Nynorsk',
      ii: 'Sichuan Yi, Nuosu',
      oc: 'Occitan',
      oj: 'Ojibwa',
      or: 'Oriya',
      om: 'Oromo',
      os: 'Ossetian, Ossetic',
      pi: 'Pali',
      ps: 'Pashto, Pushto',
      fa: 'Persian',
      pl: 'Polish',
      pt: 'Portuguese',
      pa: 'Punjabi, Panjabi',
      qu: 'Quechua',
      ro: 'Romanian, Moldavian, Moldovan',
      rm: 'Romansh',
      rn: 'Rundi',
      ru: 'Russian',
      se: 'Northern Sami',
      sm: 'Samoan',
      sg: 'Sango',
      sa: 'Sanskrit',
      sc: 'Sardinian',
      sr: 'Serbian',
      sn: 'Shona',
      sd: 'Sindhi',
      si: 'Sinhala, Sinhalese',
      sk: 'Slovak',
      sl: 'Slovenian',
      so: 'Somali',
      st: 'Southern Sotho',
      es: 'Spanish: Castilian',
      su: 'Sundanese',
      sw: 'Swahili',
      ss: 'Swati',
      sv: 'Swedish',
      tl: 'Tagalog',
      ty: 'Tahitian',
      tg: 'Tajik',
      ta: 'Tamil',
      tt: 'Tatar',
      te: 'Telugu',
      th: 'Thai',
      bo: 'Tibetan',
      ti: 'Tigrinya',
      to: 'Tonga (Tonga Islands)',
      ts: 'Tsonga',
      tn: 'Tswana',
      tr: 'Turkish',
      tk: 'Turkmen',
      tw: 'Twi',
      ug: 'Uighur, Uyghur',
      uk: 'Ukrainian',
      ur: 'Urdu',
      uz: 'Uzbek',
      ve: 'Venda',
      vi: 'Vietnamese',
      vo: 'Volapük',
      wa: 'Walloon',
      cy: 'Welsh',
      wo: 'Wolof',
      xh: 'Xhosa',
      yi: 'Yiddish',
      yo: 'Yoruba',
      za: 'Zhuang, Chuang',
      zu: 'Zulu',
      'n/a': 'n/a',
      others: 'others'
    },
    keyEvent: {
      Merger: 'Merger',
      Acquisition: 'Acquisition',
      'Joint Venture': 'Joint Venture',
      'Partnership Created': 'Partnership Created',
      'Partnership Terminated': 'Partnership Terminated',
      'Board Member Death': 'Board Member Death',
      'Board Member Firing': 'Board Member Firing',
      'Board Member Resignation': 'Board Member Resignation',
      'Executive Death': 'Executive Death',
      'Executive Firing': 'Executive Firing',
      'Executive Resignation': 'Executive Resignation',
      'Executive Retirement': 'Executive Retirement',
      'Drug Approval': 'Drug Approval',
      'Accelerated Drug Approval': 'Accelerated Drug Approval',
      'Breakthrough Therapy': 'Breakthrough Therapy',
      'Orphan Drug': 'Orphan Drug',
      'Clinical Trials': 'Clinical Trials',
      'Priority Review': 'Priority Review',
      'Patient Enrollment': 'Patient Enrollment',
      Bankruptcy: 'Bankruptcy',
      'Bankruptcy Exit': 'Bankruptcy Exit',
      'Bankruptcy Fears': 'Bankruptcy Fears',
      'Pay-per-Use': 'Pay-per-Use',
      'Earnings Guidance': 'Earnings Guidance',
      'Earnings Release': 'Earnings Release',
      'Earnings Down': 'Earnings Down',
      'Earnings Unchanged': 'Earnings Unchanged',
      'Earnings Up': 'Earnings Up',
      'Earnings Revision': 'Earnings Revision',
      'Earnings Misstatement': 'Earnings Misstatement',
      'Earnings Delayed': 'Earnings Delayed',
      EBIT: 'EBIT',
      EBITA: 'EBITA',
      EBITDA: 'EBITDA',
      'Interest Income': 'Interest Income',
      'Operating Earnings': 'Operating Earnings',
      'Pretax Earnings': 'Pretax Earnings',
      'Twitter IPO': 'Twitter IPO',
      'Analyst Downgrade': 'Analyst Downgrade',
      'Analyst Upgrade': 'Analyst Upgrade',
      'Analyst Ratings Unchanged': 'Analyst Ratings Unchanged',
      'Analyst Report': 'Analyst Report',
      'Insider Buy': 'Insider Buy',
      'Insider Gift': 'Insider Gift',
      'Insider Sell': 'Insider Sell',
      'Insider Trading Lawsuit': 'Insider Trading Lawsuit',
      'Insider Sell Registration': 'Insider Sell Registration',
      'Insider Surrender': 'Insider Surrender',
      Drilling: 'Drilling',
      'Resource Discovery': 'Resource Discovery',
      'Dividend Expectations': 'Dividend Expectations',
      'Dividend Down': 'Dividend Down',
      'Dividend Suspended': 'Dividend Suspended',
      'Dividend Unchanged': 'Dividend Unchanged',
      'Dividend Up': 'Dividend Up',
      'Automobile Accident': 'Automobile Accident',
      'Aircraft Accident': 'Aircraft Accident',
      'Dam Accident': 'Dam Accident',
      'Facility Accident': 'Facility Accident',
      'Factory Accident': 'Factory Accident',
      'Force Majeure': 'Force Majeure',
      'Freight Transport Accident': 'Freight Transport Accident',
      'Mine Accident': 'Mine Accident',
      'Pipeline Accident': 'Pipeline Accident',
      'Platform Accident': 'Platform Accident',
      'Power Outage': 'Power Outage',
      'Power Plant Accident': 'Power Plant Accident',
      'Public Transport Accident': 'Public Transport Accident',
      'Refinery Accident': 'Refinery Accident',
      Spill: 'Spill',
      'Tanker Accident': 'Tanker Accident',
      'Auditor Appointment': 'Auditor Appointment',
      'Auditor Resignation': 'Auditor Resignation',
      'Exchange Compliance': 'Exchange Compliance',
      'Exchange Noncompliance': 'Exchange Noncompliance',
      'Regulatory Investigation': 'Regulatory Investigation',
      'Short Selling Ban': 'Short Selling Ban',
      'Credit Rating Affirmation': 'Credit Rating Affirmation',
      'Credit Rating Confirmation': 'Credit Rating Confirmation',
      'Credit Rating Corrected': 'Credit Rating Corrected',
      'Credit Rating Downgrade': 'Credit Rating Downgrade',
      'Credit Rating No-rating': 'Credit Rating No-rating',
      'Credit Rating Matured': 'Credit Rating Matured',
      'Credit Rating Paid-in-full': 'Credit Rating Paid-in-full',
      'Credit Rating Reinstated': 'Credit Rating Reinstated',
      'Credit Rating Set': 'Credit Rating Set',
      'Credit Rating Unchanged': 'Credit Rating Unchanged',
      'Credit Rating Upgrade': 'Credit Rating Upgrade',
      'Credit Rating Withdrawn': 'Credit Rating Withdrawn',
      Fundraising: 'Fundraising',
      Capex: 'Capex',
      'Share Buyback': 'Share Buyback',
      'Capital Increase': 'Capital Increase',
      'Equity Shelf Registration': 'Equity Shelf Registration',
      'Going Private': 'Going Private',
      Investment: 'Investment',
      IPO: 'IPO',
      'IPO Issuance': 'IPO Issuance',
      'IPO Price': 'IPO Price',
      'IPO Lock-up': 'IPO Lock-up',
      'IPO Regulatory Approval': 'IPO Regulatory Approval',
      'IPO Regulatory Scrutiny': 'IPO Regulatory Scrutiny',
      'IPO Rumor': 'IPO Rumor',
      'Name Change': 'Name Change',
      Ownership: 'Ownership',
      'Ownership Decrease Held': 'Ownership Decrease Held',
      'Ownership Increase Held': 'Ownership Increase Held',
      'Ponzi Scheme': 'Ponzi Scheme',
      'Private Placement': 'Private Placement',
      'Public Offering': 'Public Offering',
      'Restructuring Approval': 'Restructuring Approval',
      'Reorganization Complete': 'Reorganization Complete',
      'Reorganization Considered': 'Reorganization Considered',
      'Reorganization Costs': 'Reorganization Costs',
      'Reorganization Delayed': 'Reorganization Delayed',
      'Reorganization Denied': 'Reorganization Denied',
      'Reorganization Failed': 'Reorganization Failed',
      'Restructuring Rejection': 'Restructuring Rejection',
      'Reorganization Savings': 'Reorganization Savings',
      'Reverse Stock Splits': 'Reverse Stock Splits',
      'Rights Issue': 'Rights Issue',
      Savings: 'Savings',
      'Shareholder Rights Plan': 'Shareholder Rights Plan',
      'Spin-off': 'Spin-off',
      'Stock Splits': 'Stock Splits',
      'Trading Delisting': 'Trading Delisting',
      'Trading Halt': 'Trading Halt',
      'Trading Listing': 'Trading Listing',
      'Trading Resumed': 'Trading Resumed',
      Antitrust: 'Antitrust',
      Lawsuit: 'Lawsuit',
      Blackmail: 'Blackmail',
      'Copyright Infringement': 'Copyright Infringement',
      'Patent Infringement': 'Patent Infringement',
      Corruption: 'Corruption',
      Defamation: 'Defamation',
      Discrimination: 'Discrimination',
      Embezzlement: 'Embezzlement',
      Fraud: 'Fraud',
      Sanctions: 'Sanctions',
      'Tax-evasion': 'Tax-evasion',
      'IT Hack': 'IT Hack',
      'Industrial Spying': 'Industrial Spying',
      Explosion: 'Explosion',
      Hijacking: 'Hijacking',
      Award: 'Award',
      'Business Combination': 'Business Combination',
      'Business Contract': 'Business Contract',
      'Government Contract': 'Government Contract',
      'Market Entry': 'Market Entry',
      'Market Exit': 'Market Exit',
      'Market Share': 'Market Share',
      'Product Catastrophe': 'Product Catastrophe',
      'Product Release': 'Product Release',
      'Product Delayed': 'Product Delayed',
      'Product Development': 'Product Development',
      'Product Discontinued': 'Product Discontinued',
      'Product Enhancement': 'Product Enhancement',
      'Product Fault': 'Product Fault',
      'Product Outage': 'Product Outage',
      'Product Pricing': 'Product Pricing',
      'Product Recall': 'Product Recall',
      'Product Review': 'Product Review',
      'Product Side-effects': 'Product Side-effects',
      'Project Abandoned': 'Project Abandoned',
      'Regulatory Product Application': 'Regulatory Product Application',
      'Regulatory Product Approval': 'Regulatory Product Approval',
      'Regulatory Product Approval Denied': 'Regulatory Product Approval Denied',
      'Regulatory Product Warning': 'Regulatory Product Warning',
      'Regulatory Product Review': 'Regulatory Product Review',
      Hirings: 'Hirings',
      Layoffs: 'Layoffs',
      'Workers Strike': 'Workers Strike',
      'Union Pact': 'Union Pact',
      'Shelf Registration': 'Shelf Registration',
      'COVID-19': 'COVID-19',
      MERS: 'MERS',
      SARS: 'SARS',
      Ebola: 'Ebola',
      'Avian Influenza': 'Avian Influenza',
      Plague: 'Plague',
      'African Swine Fever': 'African Swine Fever',
      'Insider Trading': 'Insider Trading',
      'Stock Manipulation': 'Stock Manipulation',
      'Real Estate Bubble': 'Real Estate Bubble',
      'Real Estate Prices': 'Real Estate Prices',
      'Rent Stabilization': 'Rent Stabilization',
      Terrorism: 'Terrorism',
      'War Declared': 'War Declared',
      'War Threat': 'War Threat',
      NFT: 'NFT',
      Blockchain: 'Blockchain',
      'Oil Embargo': 'Oil Embargo',
      'Gas Embargo': 'Gas Embargo',
      'Oil Shortage': 'Oil Shortage',
      'Gas Shortage': 'Gas Shortage',
      'Coal Embargo': 'Coal Embargo',
      'Nuclear Fuel Embargo': 'Nuclear Fuel Embargo',
      'Coal Shortage': 'Coal Shortage',
      'Nuclear Fuel Shortage': 'Nuclear Fuel Shortage',
      'Air Pollution': 'Air Pollution',
      'CO2 Emission': 'CO2 Emission',
      Deforestation: 'Deforestation',
      'Environmental Damage': 'Environmental Damage',
      Waste: 'Waste',
      'Waste Consumption': 'Waste Consumption',
      'Water Pollution': 'Water Pollution',
      'Ozone Depletion': 'Ozone Depletion',
      'Habitat Degradation': 'Habitat Degradation',
      'Human Rights': 'Human Rights',
      'Work Conditions': 'Work Conditions',
      'Health Issues': 'Health Issues',
      Harassment: 'Harassment',
      Diversity: 'Diversity',
      'Child Labor': 'Child Labor',
      Eviction: 'Eviction',
      'Forced Labor': 'Forced Labor',
      'Noise Pollution': 'Noise Pollution',
      'Human Trafficking': 'Human Trafficking',
      'Salary Conditions': 'Salary Conditions',
      'Data Privacy': 'Data Privacy',
      'Green New Deal': 'Green New Deal',
      'Sustainable Finance Initiative': 'Sustainable Finance Initiative',
      'EU Taxonomy': ' EU Taxonomy',
      'EU Circular Economy Package': ' EU Circular Economy Package',
      'Bio Diversity': ' Bio Diversity',
      'Carbon Disclosure Project': ' Carbon Disclosure Project',
      'Climate Change': ' Climate Change',
      'Credit Scoring': ' Credit Scoring',
      'Demographic Change': ' Demographic Change',
      'Design-for-Environment': ' Design-for-Environment',
      'Double Materiality': ' Double Materiality',
      'Eco-management Audit Scheme': ' Eco-management Audit Scheme',
      'Energy Performance': ' Energy Performance',
      'Equal Opportunity': ' Equal Opportunity',
      'European Federation of Financial Analysts': ' European Federation of Financial Analysts',
      'European Financial Reporting Advisory Group': ' European Financial Reporting Advisory Group',
      'Food security': ' Food security',
      'German sustainablity codex': ' German sustainablity codex',
      'Global Reporting Initiative': ' Global Reporting Initiative',
      'International Labour Organisation': ' International Labour Organisation',
      'Land Consumption': ' Land Consumption',
      'Green Lobbying': ' Green Lobbying',
      'Political Sponsorship': ' Political Sponsorship',
      'Prevention of Corruption': ' Prevention of Corruption',
      'Product Environmental Footprint': ' Product Environmental Footprint',
      'Prohibition of Discrimination': ' Prohibition of Discrimination',
      'SA8000-Certificate for Social Responsiblity': ' SA8000-Certificate for Social Responsiblity',
      'Social Standards': ' Social Standards',
      'Sustainability Accounting Standards Board': ' Sustainability Accounting Standards Board',
      'Sustainable Development Goals': ' Sustainable Development Goals',
      Insolvency: ' Insolvency',
      'Liquidity Risk': ' Liquidity Risk',
      'Account Termination': ' Account Termination',
      'Cash Withdrawal': ' Cash Withdrawal',
      'Interest Rates': ' Interest Rates',
      'Artificial Intelligence': ' Artificial Intelligence',
      'Large Language Model': ' Large Language Model',
      'Remote Work': ' Remote Work',
      Shitstorm: ' Shitstorm',
      'Machine Learning': ' Machine Learning',
      'Deep Learning': ' Deep Learning',
      'Artificial Superintelligence': ' Artificial Superintelligence',
      'Artificial General Intelligence': ' Artificial General Intelligence',
      'Embedded Finance': ' Embedded Finance',
      'Banking-as-a-Service': ' Banking-as-a-Service',
      Inflation: ' Inflation',
      'European elections': ' European elections',
      'US presidential elections': ' US presidential elections',
      'Republican Party': ' Republican Party',
      'Democratic Party': ' Democratic Party',
      'Trump-linked stock': ' Trump-linked stock',
      'Secured Debt': ' Secured Debt',
      'Unsecured Debt': ' Unsecured Debt',
      'Revolving Debt': ' Revolving Debt',
      'Installment Debt': ' Installment Debt',
      Mortgages: 'Mortgages',
      'Russia-Ukraine War': 'Russia-Ukraine War',
      'Energy Prices': 'Energy Prices',
      'Sustainability Issues': 'Sustainability Issues',
      Metaverse: 'Metaverse',
      'Virtual Reality': 'Virtual Reality',
      'US-China Strategic Competition': 'US-China Strategic Competition',
      'EU-China Strategic Competition': 'EU-China Strategic Competition',
      'EU-US Strategic Competition': 'EU-US Strategic Competition',
      'Donald Trump': 'Donald Trump',
      'Joe Biden': 'Joe Biden',
      'Kamala Harris': 'Kamala Harris',
      'Pump and Dump': 'Pump and Dump',
      'Product Ban': 'Product Ban',
      ICO: 'ICO',
      Halving: 'Halving',
      'Rug Pull': 'Rug Pull',
      'Short Selling': 'Short Selling',
      Cyberattack: 'Cyberattack',
      'Middle East Tensions': 'Middle East Tensions',
      'US Jobless Claims Fall': 'US Jobless Claims Fall',
      'US Jobless Claims Rise': 'US Jobless Claims Rise',
      'Index Inclusion': 'Index Inclusion',
      'Index Exclusion': 'Index Exclusion',
      'Increasing Tariffs': 'Increasing Tariffs',
      'Decreasing Tariffs': 'Decreasing Tariffs',
      'Electric Vehicles': 'Electric Vehicles',
      'Digital Euro': 'Digital Euro',
      'Shooting Attack': 'Shooting Attack',
      'Michelle Obama': 'Michelle Obama',
      others: 'others'
    }
  },

  ExecutiveSummary: {
    title: 'Executive Summary',
    note: 'This executive summary was generated by Stockpulse\' AI. The summary is based on the last few news items related to the search term. The input news can be in different languages and are integrated into the summary in the output language. The executive summary is trained to recognize the main topics in the input messages and to summarize them in a few sentences.',
    sources: {
      generatedBy: 'This executive summary was generated by the Stockpulse AI.',
      generatedByAt: 'This executive summary was generated at {publishTime} by the Stockpulse AI.',
      label: 'News sources',
      showMore: 'Show more'
    },
    notices: {
      notEnoughTopRatedSources: 'Not enough top rated sources are available. This might affect the quality of the executive summary'
    },
    empty: 'Not enough top rated sources are available.'
  },

  TitleComponent: {
    empty: {
      associated: 'We could not detect any related financial instrument for the given search term.',
      related: 'No related title found.'
    },
    note: 'This box shows related financial instruments of main detected asset.',
    detectedAsset: {
      note: 'This displays the (stock) prices of the selected entity.'
    }
  },

  Assets: {
    title: {
      top: 'Top Asset',
      related: 'Related'
    }
  },

  ContactSalesDialog: {
    infoText: 'Stockpulse provides data and AI analyzes for more than 60k stocks, 20k cryptocurrencies, indices, commodities, financially relevant topics and people - with a unique history for sentiment analysis going back to 2011. We would be happy to send you an individual offer for access to our databases and AI tools.',
    infoTextShort: 'Stockpulse provides AI analytics on financially relevant topics and people - with a unique history for sentiment analysis going back to 2011. We would be happy to send you an individual offer for access to our AI tools.'
  },
  LoginPage: {
    alreadyLoggedIn: 'User {user} is already logged in',
    navigateToHome: 'go to index'
  },
  LoginComponent: {
    login: {
      label: 'Login',
      email: {
        label: 'Email'
      },
      password: {
        label: 'Password'
      },
      rememberMe: {
        label: 'Remember me'
      },
      forgotPassword: {
        label: 'Forgot Password?'
      },
      noAccount: {
        label: 'You don\'t have an account yet?'
      },
      register: {
        label: 'Register'
      }
    },
    reset: {
      label: 'Reset password',
      email: {
        label: 'Email',
        hint: 'Enter your email address and we\'ll send you a link to change your password.'
      },
      sendLinkToResetPassword: 'Continue',
      cancel: 'Cancel',
      success: {
        label: 'Success',
        message: 'Follow the instructions in your email to reset your password.',
        close: 'ok'
      }
    },
    setPassword: {
      label: 'Set your new password',
      newPassword: {
        label: 'New password'
      },
      newPasswordConfirm: {
        label: 'Confirm new password'
      },
      save: {
        label: 'Save'
      },
      success: {
        label: 'Success',
        message: 'You can now login using your new password',
        close: 'ok'
      }
    }

  },
  AuthenticationService: {
    message: {
      login:
        {
          success: 'Login successful',
          failure: 'Login failed',
          invalidCredentials: 'You have entered an invalid username or password',
          usernameRequired: 'Email required',
          passwordRequired: 'Password required'
        },
      register:
        {
          failure: 'Registration failed',
          duplicateUser: 'Proviced Email is already in use.',
          usernameRequired: 'Email required',
          passwordRequired: 'Password required',
          firstNameRequired: 'First name required',
          lastNameRequired: 'Surname required',
          tokenRequired: 'Cloudflare verification required'
        },
      reset: {
        invalidEmail: 'Please provide a valid email address.',
        failure: 'Password reset failed. Please try again or contact support.',
        message: 'The request new password email was sent and should arrive shortly. Please check your inbox and click on the link provided to initiate password reset.'
      },
      setPassword: {
        invalidToken: 'No password reset token provided.',
        invalidPassword: 'Password required.',
        invalidPasswordConfirm: 'Password confirmation required',
        passwordMismatch: 'Passwords do not match.',
        failure: 'Failed to update your password. Please try again by manually copying the link from the reset email to your browser address bar or contact support.',
        message: 'Follow the instructions in your email to reset your password.',
        passwordFormat: 'You have to provide a password with at least 8 characters. In addition, we recommend usage of at least one special character and a number.'
      },
      orderSubscription: {
        success: 'Your order has successfully been placed.',
        failure: 'Failed to order your subscription. Please try again.'
      },
      cancellation: {
        success: 'Your cancellation request has been received.',
        failure: 'Failed to cancel your subscription. Please try again.'
      }
    },
    api: {
      account0: 'Your account is not eligible for this action.',
      account1: 'No or wrong fields to change provided.',
      account2: 'You have to provide an email address.',
      account3: 'You have to provide a password with at least 8 characters.',
      account4: 'No values given to save.',
      account5: 'You are trying to change fields you are not allowed to.',
      account6: 'No options given to save.',
      account7: "You need to provide a 'newpassword' parameter.",
      account8: 'Missing parameters.',
      account9: 'Email does not exist in db.',
      // account10: "You can't remove non-gekko-accounts.",
      account11: 'Username already in database.',
      // account12: "The account doesn't exist or is no gekko account.",
      account13: 'The provided credentials do not match any account, or the account might not have a valid subscription.',
      account14: 'Invalid access token.',
      account15: 'Please use HTTP basic authentication.',
      account16: 'This account is inactive.',
      account17: 'Email is already valid.',
      account18: 'You can only send one password reset request every 24 hours.',
      account19: 'Rate limit reached',
      account20: 'Too many requests, API busy.',
      account21: 'Unsupported format.',
      account30: 'Expired access token.',
      account32: 'Malformed parameter(s).',
      account33: 'Invalid JSON.',
      account34: 'Your account is not eligible to access this version of the API.',
      account35: 'Your account is not eligible to access this feature of the API.',
      account36: 'Your account is not eligible to access this region.',
      account37: 'Your account is not eligible to view this format.',
      account38: 'Your account is not eligible to access AI functions.',
      account39: 'Invalid token.',
      account40: 'Error validating token.'
    }
  },
  WebSocketComponent: {
    message: {
      connectionLost: 'Connection lost, reconnecting...'
    }
  },
  Notifications: {
    newMessagesInSources: 'New Messages on {source}',
    newMessage: 'New Message by {name} on {source}'
  },
  InitialLoadingComponent: {
    reloadPageButton: 'Reload Page',
    reloadingInLabel: 'Reloading in ',
    loadingDataLabel: 'Loading data...',
    loadingDataLongerThanExpectedLabel: 'Data loading is taking longer than expected. We anticipate completion shortly. If you prefer, you may reload the Page to try again.',
    loadDataFailedLabel: 'Loading Stockpulse ai failed, you may reload the Page to try again.',
    assetsLabel: 'Assets',
    exchangesLabel: 'Exchanges',
    countriesLabel: 'Countries',
    sourcesLabel: 'Sources',
    keyEventsLabel: 'Key events',
    sectorsLabel: 'Sectors',
    trendingAssetsLabel: 'Trending Assets'
  },
  cancellation: {
    Form: {
      firstName: {
        label: 'First name',
        placeholder: 'Enter your first identifier',
        error: 'Please enter your first name'
      },
      lastName: {
        label: 'Last name',
        placeholder: 'Enter your last identifier',
        error: 'Please enter your last name'
      },
      email: {
        label: 'Email',
        placeholder: 'Enter your email for confirmation',
        error: 'Please enter your email address'
      },
      reason: {
        label: 'Reason for Termination',
        placeholder: 'Enter the reason for termination',
        error: 'Please enter a reason'
      }

    },
    title: 'Terminate Contract',
    terminationNote: 'We will process your request and email you a confirmation once your subscription is canceled.',
    cancelNow: 'Terminate Now',
    confirmationMessage: 'Your termination request has been submitted.',
    errorMessage: 'Please fill in all required fields.',
    login: 'Would you like your data to be entered automatically? Simply log into your account.',
    goToLogin: 'Login'
  }
}
