import { defineStore } from 'pinia'
import { computed, ComputedRef, Ref, ref, watch } from 'vue'
import { date } from 'quasar'
import PermissionService from 'src/services/PermissionService'
import {
  AbsoluteOrRelativeTimeRange,
  AbsoluteTimeRange,
  getAbsoluteTimeRangeFromRelativeTimeRange,
  isRelativeTimeRange,
  RelativeTimeRange
} from 'src/helper/DateTimeHelper'
import { useRouter } from 'vue-router'
import { replaceCurrentQueryParams } from 'src/helper/RouterHelper'
import { Auxiliary } from 'src/helper/Auxiliary'

const relativeTimeRangeOptions: RelativeTimeRange[] = [
  {
    name: 'Real time',
    duration: { hours: 24 }
  },
  {
    name: 'Last 24 hours',
    duration: { hours: 24 }
  },
  {
    name: 'Last 3 days',
    duration: { days: 3 }
  },
  {
    name: 'Last 7 days',
    duration: { days: 7 }
  },
  {
    name: 'Last 30 days',
    duration: { days: 30 }
  },
  {
    name: 'Last 90 days',
    duration: { days: 90 }
  },
  {
    name: 'Last 6 months',
    duration: { months: 6 }
  },
  {
    name: 'Last 1 year',
    duration: { years: 1 }
  }
]

export const DEFAULT_TIMERANGE = relativeTimeRangeOptions[2]

/**
 * TimeRageStore to globally set selected Time-Range
 */
export const timeRangeStore = defineStore('timeRange', () => {
  const router = useRouter()
  const permissionService = new PermissionService()

  const selectedTimeRange : Ref<AbsoluteOrRelativeTimeRange> = ref(DEFAULT_TIMERANGE)

  watch(() => [
    router.currentRoute.value.query?.relativeTimeRange,
    router.currentRoute.value.query?.startDate,
    router.currentRoute.value.query?.endDate],
  (newValue, oldValue) => {
    if (Auxiliary.compareObjects(newValue, oldValue ?? {}) === 0) {
      return
    }
    if (router.currentRoute.value.query?.relativeTimeRange) {
      const timeRangeIndex = getRelativeTimeRangeOptionIndexByName(
        router.currentRoute.value.query?.relativeTimeRange as string)
      selectedTimeRange.value = timeRangeIndex !== -1 ? relativeTimeRangeOptions[timeRangeIndex] : DEFAULT_TIMERANGE
      return
    } else if (router.currentRoute.value.query?.startDate && router.currentRoute.value.query?.endDate) {
      const startDate = new Date(parseInt(router.currentRoute.value.query?.startDate as string))
      const endDate = new Date(parseInt(router.currentRoute.value.query?.endDate as string))
      if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
        selectedTimeRange.value = {
          startDate,
          endDate
        }
        return
      }
    }

    selectedTimeRange.value = DEFAULT_TIMERANGE
  }, { immediate: true, deep: true })

  const selectedAbsoluteTimeRange: ComputedRef<AbsoluteTimeRange | undefined> = computed(() => {
    return isRelativeTimeRange(selectedTimeRange.value) ? undefined : selectedTimeRange.value
  })
  const selectedRelativeTimeRange: ComputedRef<RelativeTimeRange | undefined> = computed(() => {
    return isRelativeTimeRange(selectedTimeRange.value) ? selectedTimeRange.value : undefined
  })

  function getCurrentStartDate () : Date {
    if (isRelativeTimeRange(selectedTimeRange.value)) {
      return getAbsoluteTimeRangeFromRelativeTimeRange(selectedTimeRange.value).startDate
    }
    return selectedTimeRange.value.startDate
  }

  function getCurrentEndDate (): Date {
    if (isRelativeTimeRange(selectedTimeRange.value)) {
      return getAbsoluteTimeRangeFromRelativeTimeRange(selectedTimeRange.value).endDate
    }
    return selectedTimeRange.value.endDate
  }

  function getCurrentExtendedStartDate () : Date {
    const currentSelection = selectedTimeRange.value
    if (isRelativeTimeRange(currentSelection)) {
      // For a dynamic time period choose the next one as extended start date
      const selectedRelativeTimeRangeIndex =
          relativeTimeRangeOptions.findIndex(timeRange => timeRange.name === currentSelection.name)
      const extendedTimeRange = relativeTimeRangeOptions.at(selectedRelativeTimeRangeIndex + 1) || currentSelection
      const newStartDate = getAbsoluteTimeRangeFromRelativeTimeRange(extendedTimeRange).startDate

      if (!permissionService.isTimeRangePermittedByHistoryMonths(newStartDate)) {
        return permissionService.getEarliestPermittedStartDateByHistoryMonths()
      }

      return newStartDate
    }

    // For a fixed time period use the start date with doubled duration
    const startTs = currentSelection.startDate.getTime()
    const endTs = currentSelection.endDate.getTime()

    // Calculated extended start date by subtracting twice the duration of selected time period
    const newStartDate = new Date(startTs - (endTs - startTs))

    if (!permissionService.isTimeRangePermittedByHistoryMonths(newStartDate)) {
      return permissionService.getEarliestPermittedStartDateByHistoryMonths()
    }

    return newStartDate
  }

  async function setRelativeTimeRange (timeRange: RelativeTimeRange): Promise<void> {
    await replaceCurrentQueryParams(router, { relativeTimeRange: timeRange.name, startDate: null, endDate: null }, '#')
  }

  async function setAbsoluteTimeRange (timeRange: AbsoluteTimeRange): Promise<void> {
    await replaceCurrentQueryParams(router, {
      startDate: timeRange.startDate.getTime().toString(),
      endDate: timeRange.endDate.getTime().toString(),
      relativeTimeRange: null
    }, '#')
  }
  function getApiTimestamp (date: Date): string {
    return (date.getTime() / 1000).toFixed(3)
  }
  function getPeriod (): string {
    return ''
    /* const formatter = new Intl.DateTimeFormat($q.lang.isoName, { dateStyle: 'short', timeStyle: 'short' })

      const start = formatter.format(this.getCurrentStartDate)
      const to = '' // JLTODO
      const end = formatter.format(this.getCurrentEndDate)

      return `${start} ${to} ${end}` */
  }

  function getRelativeTimeRangeOptionIndexByName (name: string) :number {
    return relativeTimeRangeOptions.findIndex(timeRange => timeRange.name === name)
  }

  function isRelativeTimeRangeSelected (): boolean {
    return isRelativeTimeRange(selectedTimeRange.value)
  }

  function isRealtimeSelected (): boolean {
    return isRelativeTimeRangeSelected() && selectedTimeRange.value?.name === 'Real time'
  }

  function getSelectionLabel (): string {
    const currentSelection = selectedTimeRange.value
    if (isRelativeTimeRange(currentSelection)) {
      return currentSelection.name
    }
    if (!currentSelection.startDate) {
      return '-'
    }

    const startFormatted = date.formatDate(currentSelection.startDate, 'YYYY-MM-DD')
    const duration = (currentSelection.endDate.getTime() - currentSelection.startDate.getTime()) / 1000

    let range, symbol
    if (duration < 3600) {
      range = Math.round(duration / 60)
      symbol = 'm'
    } else if (duration < 22 * 3600) {
      range = Math.round(duration / (3600))
      symbol = 'h'
    } else if (duration < 28 * 24 * 3600) {
      range = Math.round(duration / (24 * 3600))
      symbol = 'd'
    } else {
      range = Math.round(duration / (30 * 24 * 3600))
      symbol = 'M'
    }
    return startFormatted + ' +' + range + symbol
  }

  return {
    selectedTimeRange,
    selectedAbsoluteTimeRange,
    selectedRelativeTimeRange,
    getSelectionLabel,
    getPeriod,
    getApiTimestamp,
    isRelativeTimeRangeSelected,
    isRealtimeSelected,
    setAbsoluteTimeRange,
    setRelativeTimeRange,
    getCurrentEndDate,
    getCurrentStartDate,
    getCurrentExtendedStartDate,
    getRelativeTimeRangeOptionIndexByName,
    relativeTimeRangeOptions
  }
})
