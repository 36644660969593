import { defineStore } from 'pinia'
import { api } from 'boot/axios'
import AuthenticationService, { LoginCredentials, UserData } from 'src/services/AuthenticationService'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import NotificationService from 'src/services/NotificationService'
import { Auxiliary } from 'src/helper/Auxiliary'
import * as Sentry from '@sentry/vue'

const STORE_CURRENT_VERSION = 1
const USER_GUEST = 'guest'

const guestUser = {
  username: USER_GUEST
} as UserData

export const useAuthUserStore = defineStore('auth/user', () => {
  // State
  const credentials = ref<LoginCredentials | null>(null)
  const loggedInDate = ref<Date | null>(null)
  const user = ref<UserData>(Auxiliary.cloneObject(guestUser))

  // Getters
  const fullName = computed(() => `${user.value.first_name} ${user.value.last_name}`)
  const initials = computed(() => `${user.value.first_name?.[0] ?? ""}${user.value.last_name?.[0] ?? ""}`.toUpperCase())
  const isLoggedIn = computed(() => user.value.username !== guestUser.username)
  const hasValidSubscription = computed(() =>
    user.value.username !== guestUser.username &&
    user.value.valid_until > ((new Date()).getTime() / 1000)
  )
  const language = computed(() => user.value.language)
  const locale = computed(() => {
    switch (user.value.language) {
    case 'de':
      return 'de-DE'
    case 'en':
    case undefined:
      return 'en-US'
    default:
      Sentry.captureMessage(
        `User language not in Mapping. User: ${user.value.username}, Language: ${user.value.language}`
      )
      return 'en-US'
    }
  })
  const getCredentials = computed(() => credentials.value)
  const maxTimeSpanDays = computed(() => user.value.timespan_days || 366)
  const historyMonths = computed(() => user.value.history_months)
  const username = computed(() => user.value.username)
  const firstName = computed(() => user.value.first_name)
  const lastName = computed(() => user.value.last_name)
  const pendingSubscriptions = computed(() => user.value.pendingSubscriptions || [])

  // Actions
  async function setUserData (
    userData: UserData,
    creds: LoginCredentials, triggerNotifications: boolean
  ) : Promise<void> {
    credentials.value = creds
    api.defaults.headers.common.Authorization = 'Basic ' + creds.toAuthString()
    updateUserData(userData)

    if (triggerNotifications) {
      const router = useRouter()
      const notificationService = new NotificationService(router)
      if (userData.username !== guestUser.username && userData.valid_until > ((new Date()).getTime() / 1000)) {
        if (userData.validTime < 60 * 60 * 24 * 7) {
          notificationService.notifySoonExpiration(
            userData.valid_until,
            userData?.pendingSubscriptions?.length > 0
          )
        }
      } else {
        notificationService.notifySubscriptionExpired()
      }
    }
  }

  function updateUserData (userData: UserData) : void {
    user.value = userData
    loggedInDate.value = new Date()
    Sentry.setUser({ id: userData.user_id })
  }

  async function logout () : Promise<void> {
    delete api.defaults.headers.common.Authorization
    credentials.value = null
    user.value = Auxiliary.cloneObject(guestUser)
    loggedInDate.value = null
    Sentry.setUser(null)
  }

  return {
    user,
    credentials,
    loggedInDate,
    fullName,
    initials,
    isLoggedIn,
    hasValidSubscription,
    language,
    locale,
    getCredentials,
    maxTimeSpanDays,
    historyMonths,
    username,
    firstName,
    lastName,
    pendingSubscriptions,
    setUserData,
    updateUserData,
    logout
  }
}, {
  // Persist Options
  persist: {
    storage: localStorage,
    debug: true,
    afterRestore: context => {
      if (context.store.credentials) {
        context.store.credentials = new LoginCredentials(
          context.store.credentials.username,
          context.store.credentials.password
        )

        api.defaults.headers.common.Authorization = 'Basic ' + context.store.credentials.toAuthString()

        const authService = new AuthenticationService()
        authService.login(context.store.credentials, true).then(result => {
          if (result.success && result.userData != null) {
            context.store.setUserData(result.userData, context.store.credentials, true)
          } else {
            context.store.$reset()
          }
        }).catch(() => {
          context.store.$reset()
        })
      }
    }
  },
  resetCache: {
    version: STORE_CURRENT_VERSION
  }
})
